// Client
import { httpClient, token } from '../client';
import { format, clean } from 'rut.js';

// Definitions
import { AxiosResponse, CancelTokenSource } from 'axios';
import { Appointments } from '../../../../domain/models';

// Resource
const resource = '/appointments';

// Dynamic token source
let source: CancelTokenSource;

// Definitions
type CreateAppointmentProps = {
  patientId: string;
  slotsIds: string[];
};

type GetAppointmentProp = {
  id: string;
  dni: string; // FORMAT 12.345.678-9
};

export default {
  cancel: () => source?.cancel('Service cancelled'),
  async create(props: CreateAppointmentProps) {
    source = token.source();
    const { patientId, slotsIds } = props;
    const tokenApi = sessionStorage.getItem('token')
    return httpClient.post(
      `${resource}`,
      {
        appointment: {
          slots: slotsIds.map(parseInt),
          patient: parseInt(patientId),
          freeSlotSubscription: true,
          channel: 'website'
        }
      },
      {
        cancelToken: source.token,
        headers: {
          Authorization: tokenApi
        }
      }
    );
  },
  async get(props: GetAppointmentProp): Promise<AxiosResponse<Appointments>> {
    const { id, dni } = props;
    const tokenApi = sessionStorage.getItem('token')
    source = token.source();
    const formattedDni = format(clean(dni)).replace(/\./g, '');
    return httpClient.get(`${resource}/${id}?rut=${formattedDni}`, {
      cancelToken: source.token,
      headers: {
        Authorization: tokenApi
      }
    });
  }
};
