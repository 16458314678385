// Client
import { httpClient, token } from '../client';
import { format, parseISO } from 'date-fns';

// Definitions
import { AxiosResponse, CancelTokenSource } from 'axios';
import { Patients } from '../../../../domain/models';

// Resource
const resource = '/patients';

// Dynamic token source
let source: CancelTokenSource;

// Definitions
type CreatePatientProps = {
  dni: string;
  firstName: string;
  fatherLastName: string;
  motherLastName: string;
  phoneNumber: string;
  gender: string;
  planId: string;
  email: string;
  birthDate: string; // ISO-8601
};

export default {
  cancel: (): void => source?.cancel('Service cancelled'),
  async create(props: CreatePatientProps): Promise<AxiosResponse<Patients>> {
    source = token.source();
    const {
      dni: rut,
      firstName,
      fatherLastName: lastName,
      motherLastName: secondLastName,
      planId: coverageProvider,
      phoneNumber: cellphone,
      birthDate,
      gender,
      email
    } = props;
    const tokenApi = sessionStorage.getItem('token')
    return await httpClient.post(
      `${resource}`,
      {
        patient: {
          rut,
          firstName,
          lastName,
          secondLastName,
          cellphone,
          gender,
          email,
          birthDate: format(parseISO(birthDate), 'yyyy-MM-dd'),
          coverageProvider: parseInt(coverageProvider)
        }
      },
      { cancelToken: source.token,
        headers: {
          Authorization: tokenApi
        }
      }
    );
  }
};
