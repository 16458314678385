/**
 * From:
 * - https://lottiefiles.com/wibbycreative
 * - https://lottiefiles.com/ZHANGXIN
 * - https://lottiefiles.com/mysosfamily
 * - https://lottiefiles.com/sravan
 * - https://lottiefiles.com/desiertoysemilla
 * - https://lottiefiles.com/caracolor
 * - https://lottiefiles.com/user/303366
 * - https://lottiefiles.com/user/261957
 */
export const lottie: any = {
  document: require('./lottie/document.json'),
  organize: require('./lottie/organize.json'),
  loading: require('./lottie/loading.json'),
  clinic: require('./lottie/clinic.json'),
  meditate: require('./lottie/meditate.json')
};
export const finder = (name: any) => lottie[name];
