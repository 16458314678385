// Definitions
import { Slots } from '../../../../domain/models';
import { HttpClient } from '../http.client';
import { format } from 'date-fns';
import endpoints from '../endpoints';
import defaultParameter from './params.default';

// Definitions
type PropsGetSlots = {
  from: Date;
  to: Date;
  coverageProvider?: string;
  healthcareService?: string;
  specialty?: string;
  practitionerId?: string;
};

type GetSlotQueryParams = {
  limit?: number;
  from?: string;
  to?: string;
  coverageProvider?: string;
  healthcareService?: string;
  specialty?: string;
  practitionerId?: string;
};

class SlotRepository extends HttpClient {
  constructor(resourceUrl: string) {
    super();
    this.addEndpointBase(resourceUrl);
  }

  private formatDate(date: Date): string {
    return format(date, 'yyyy-MM-dd');
  }

  async getAvailablesSlots(props: PropsGetSlots): Promise<Slots> {
    const { from, to, coverageProvider, healthcareService, specialty, practitionerId } = props;

    const params: GetSlotQueryParams = {
      from: this.formatDate(from),
      to: this.formatDate(to),
      limit: defaultParameter.limit,
      coverageProvider,
      healthcareService,
      specialty,
      practitionerId
    };

    return this.get(`/availableSlots`, { params, headers: {
      Authorization: sessionStorage.getItem('token')
    } });
  }
}

export default new SlotRepository(endpoints.api);
