// Client
import { httpClient, token } from '../client';

// Definitions
import { AxiosResponse, CancelTokenSource } from 'axios';
import Service from '../../../../domain/models/sked/Service';

// Resource
const resource = '/search/services';

const defaultParams = {
  patientId: null,
  searchPhrase: '',
  includeAvailabilities: true,
  includeChannel: true,
  regionId: null,
  onlyAssignedToLocationsOfUser: false,
  count: true,
  exclusionList: []
};

interface Props {
  locationId: string;
  coveragePlanId: string;
  areaId: string;
  patientId: string | null;
  searchPhrase?: string;
  resourceId?: string;
  includeAvailabilities?: boolean;
  includeChannel?: boolean;
  regionId?: string;
  onlyAssignedToLocationsOfUser?: boolean;
  count?: boolean;
  exclusionList?: string[];
}

// Dynamic token source
let source: CancelTokenSource;

export default {
  async get(props: Props): Promise<AxiosResponse<Service[]>> {
    const tokenApi = sessionStorage.getItem('token')
    source = token.source();
    const params = Object.assign(defaultParams, props);
    return httpClient.post(`${resource}`, params, { cancelToken: source.token, headers: {
      Authorization: tokenApi
    } });
  }
};
