import Center from '../../domain/models/sked/slots/Center';
import Resource from '../../domain/models/sked/slots/Resource';
import Service from '../../domain/models/sked/slots/Service';
import Slot from '../../domain/models/sked/slots/Slot';

export interface Payload {
  key: keyof State;
  data: unknown;
}

interface State {
  slots: Slot[];
  centers: Center[];
  services: Service[];
  resources: Resource[];
}

// Default value of store
export const initialState = {
  slots: [],
  centers: [],
  services: [],
  resources: []
} as State;

// Action Types
const SET = 'agenda-web/slots/SET';

// actions
export type ActionTypes = { type: typeof SET; payload: Payload };

export const set = (key: keyof State, data: unknown): ActionTypes => {
  const payload = { key, data };
  return { type: SET, payload };
};

// Reducer
const slotsReducer = (state: State = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case SET: {
      const { key, data } = action.payload;
      return {
        ...state,
        [key]: data
      };
    }
    default:
      return state;
  }
};

export default slotsReducer;
