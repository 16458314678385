import axios from 'axios';
import { api } from '../../../domain/client.json';

// Default config for axios instance
//const config = { baseURL };

// Creating the instance for axios
const httpClient = axios.create(api);

// Creating token for instance
const token = axios.CancelToken;

export { httpClient, token };
