import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import Patient from '../../domain/models/sked/Patient';
import patientService from '../../infrastructure/services/sked/repositories/patients';
import { RootStore } from '../store';

export enum Status {
  Updating = 'updating',
  Creating = 'creating'
}
interface FindPatientProps {
  rut: string;
  token: string;
}

export interface MyState {
  rut: string;
  patient?: Patient;
  loading: boolean;
  error?: boolean;
  validRut?: boolean;
  status: Status;
}
export interface HttpResponseStructure {
  message?: string;
  status?: number;
}

export const initialState = {
  rut: '',
  patient: undefined,
  loading: false,
  error: undefined,
  validRut: undefined,
  status: Status.Creating
} as MyState;

// Action Types
export const START_FETCH = 'START_FETCH';
export const FINISH_FETCH = 'FINISH_FETCH';
export const ERROR_FETCH = 'ERROR_FETCH';
export const SET_VALID_RUT = 'SET_VALID_RUT';
export const SET_PATIENT = 'SET_PATIENT';
export const SET_STATUS = 'SET_STATUS';
export const SET_RUT = 'SET_RUT';

// actions
export type ActionTypes =
  | { type: typeof START_FETCH }
  | { type: typeof FINISH_FETCH; patient?: Patient }
  | { type: typeof ERROR_FETCH }
  | { type: typeof SET_VALID_RUT; payload: boolean }
  | { type: typeof SET_PATIENT; patient?: Patient }
  | { type: typeof SET_STATUS; status: Status }
  | { type: typeof SET_RUT; rut: string };

export const findPatient = (
  props: FindPatientProps
): ThunkAction<Promise<HttpResponseStructure>, RootStore, unknown, ActionTypes> => async (
  dispatch,
  getState
) => {
  const { rut, token } = props;
  dispatch({ type: 'START_FETCH' });
  try {
    const {
      data: { patientId, token: tokenBack },
      status
    } = await patientService.verifyRut({ rut, token });
    sessionStorage.setItem('token', tokenBack )
    if (patientId) {
      sessionStorage.setItem('patientId', patientId )
      const { data: {response: patient} } = await patientService.getOne(patientId);
      dispatch({ type: 'FINISH_FETCH', patient });
    } else {
      dispatch({ type: 'FINISH_FETCH', patient: undefined });
    }
    return Promise.resolve({ message: 'rut validated', status });
  } catch (error) {
    dispatch({ type: 'ERROR_FETCH' });
    if (error.response) {
      const { status, data } = error.response;
      return Promise.reject({
        message: 'Error al verificar el rut - reintentar por favor.',
        status
      });
    } else {
      const { name, message: msg } = error;
      return Promise.reject({ message: 'Servidor temporalmente no disponible', status: 500 });
    }
  }
};

export const setRut = (rut: string): ActionTypes => {
  return { type: SET_RUT, rut };
};
export const setValidRut = (payload: boolean): ActionTypes => {
  return { type: SET_VALID_RUT, payload };
};
export const setStatus = (status: Status): ActionTypes => {
  return { type: SET_STATUS, status };
};
export const setPatient = (patient: Patient): ActionTypes => {
  return { type: SET_PATIENT, patient };
};

const patientsReducer = (state: MyState = initialState, action: ActionTypes): MyState => {
  switch (action.type) {
    case START_FETCH: {
      return {
        ...state,
        loading: true
      };
    }
    case FINISH_FETCH: {
      return {
        ...state,
        loading: false,
        error: false,
        patient: action.patient
      };
    }
    case ERROR_FETCH: {
      return {
        ...state,
        error: true,
        loading: false
      };
    }
    case SET_RUT:
      return {
        ...state,
        rut: action.rut
      };
    case SET_VALID_RUT:
      return {
        ...state,
        validRut: action.payload
      };
    case SET_PATIENT:
      return {
        ...state,
        patient: action.patient
      };
    case SET_STATUS:
      return {
        ...state,
        status: action.status
      };

    default:
      return state;
  }
};

export default patientsReducer;
