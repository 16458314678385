import { Reducer } from 'react';
import patientReducerSked from './patientReducerSked';
import {
  State,
  Action,
  Status,
  HttpResponseStructure,
  FindPatientProps
} from './patientDefinitionsSked';
import { default as patientService } from '../../../services/sked/repositories/patients';

import { useReducerAsync, AsyncActionHandlers } from 'use-reducer-async';
import Patient from '../../../../domain/models/sked/Patient';

// Default value of store
export const initialState = {
  patient: undefined,
  loading: false,
  error: undefined,
  valid: false,
  status: Status.Creating
} as State;

/* Esta es una implementacion un poco rara e hibrida.
 Se necesitaba poder hacer await de un dispatch llamado desde un componente, pero esta libreria (use-reducer-async)
 lo que hace es hacer dispatch asyncronos, pero solo internamente, en las funciones que defina dentro de asyncActionHandlers
 que se puede ver que aprovecho internamente en la fn findPatient.
 Sin embargo, para poder hacer efectivo el await de un dispatch en un componente externo, es necesario exportar esta fn asyncrona.
 Implementaciones:
 Revisar archivo VerifyRut.tsx, metodo onChangeDni() se ocupa un dispatch({type: 'SET_VALID'}), que no necesito esperarlo.
 Sin embargo,en metodo onClickButton(), si que es necesario ocupar la fn asyncrona exportada findPatient(), y hacer await de ella,antes de pasar a la sgte ruta. 
 Esta implementacion, está sujeta a cambios quizas en un futuro, por algo mas solido como redux, etc.
 Por ahora funciona bien :=)
 */
export type AsyncAction = { type: 'TEST' };
const asyncActionHandlers: AsyncActionHandlers<Reducer<State, Action>, AsyncAction> = {
  // TODO: probar el findPatient() aqui dentro, y si funca queda mas ordenado, y dejar aca las outer fns asyncronas
  TEST: ({ dispatch }) => async (action) => {}
};

const usePatientStoreSked = () => {
  const [state, dispatch] = useReducerAsync<
    Reducer<State, Action>,
    AsyncAction,
    AsyncAction | Action
  >(patientReducerSked, initialState, asyncActionHandlers);
  const findPatient = async (props: FindPatientProps): Promise<HttpResponseStructure> => {
    const { rut, token } = props;
    dispatch({ type: 'START_FETCH' });
    try {
      const {
        data: { patientId, token: tokenBack },
        status
      } = await patientService.verifyRut({ rut, token });
      sessionStorage.setItem('token', tokenBack )
      if (patientId) {
        sessionStorage.setItem('patientId', patientId )
        const { data } = await patientService.getOne(patientId);
        dispatch({ type: 'FINISH_FETCH', patient: data.response });
      } else {
        dispatch({ type: 'FINISH_FETCH', patient: undefined });
      }
      return Promise.resolve({ message: 'rut validated', status });
    } catch (error) {
      dispatch({ type: 'ERROR_FETCH' });
      if (error.response) {
        const { status, data } = error.response;
        return Promise.reject({
          message: 'Error al verificar el rut - reintentar por favor.',
          status
        });
      } else {
        const { name, message: msg } = error;
        return Promise.reject({ message: 'Servidor temporalmente no disponible', status: 500 });
      }
    }
  };
  const patientIsRegistered = (): boolean => {
    return state.patient !== undefined;
  };
  const setPatient = async (patient: Patient): Promise<void> => {
    await dispatch({ type: 'SET_PATIENT', patient });
  };

  return { state, dispatch, findPatient, patientIsRegistered, setPatient };
};

export default usePatientStoreSked;
