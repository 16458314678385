const resources = {
  practitionerRoles: '/api/practitionerRoles',
  healthcareServicesSpecialties: '/api/healthcareServicesSpecialties',
  healthcareServices: '/api/healthcareServices',
  areas: '/areas',
  coveragePlans: '/coverage-plans',
  locations: '/search/locations',
  base: '/',
  empty: '',
  api: '/api',
  slots: '/slots'
};
export default resources;
