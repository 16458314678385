import React, { createContext, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import userService from '../../services/med360/repositories/security';
import Patient from '../../../domain/models/Patients';

// Definitions
type Methods = {
  signIn: (username: string, password: string) => void;
  signOut: () => void;
};

type Provider = {
  patient?: Patient;
  methods?: Methods;
  isLogin?: boolean;
};

/**
 * Function
 */
const useProvideAuth = (): Provider => {
  // Hooks
  const [cookies, setCookies, removeCookie] = useCookies(['med-360-agenda']);

  // State
  const [patient, setPatient] = useState<Patient | undefined>(undefined);
  const [isLogin, setIsLogin] = useState(false);

  const methods = {
    signIn: async (username: string, password: string): Promise<boolean> => {
      try {
        const { authorization } = await userService.login({ username, password });
        setCookies('authorization', authorization?.simpleToken);
        setPatient(authorization?.patient);
        setIsLogin(true);
        return true;
      } catch (error) {
        return false;
      }
    },
    me: async (): Promise<boolean> => {
      try {
        const { result } = await userService.getInfoUser(cookies.authorization);
        setPatient(result?.patients[0]);
        setIsLogin(true);
        return true;
      } catch (error) {
        setIsLogin(false);
        return false;
      }
    },
    signOut: (): boolean => {
      removeCookie('authorization');
      return true;
    }
  };

  useEffect(() => {
    if (cookies.authorization) methods.me();
  }, [cookies]);

  return { patient, methods, isLogin };
};

/**
 * Context
 */
const authContext = createContext<Provider>({ patient: undefined });

/**
 * Provider
 * @param children
 * @constructor
 */
const AuthProvider = ({ children }: any) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

/**
 * Use Auth for components
 */
const useAuth = (): Provider => useContext(authContext);

export { AuthProvider, useAuth };
