import { State, Action } from './patientDefinitionsSked';

const patientReducerSked = (state: State, action: Action) => {
  switch (action.type) {
    case 'START_FETCH': {
      return {
        ...state,
        loading: true
      };
    }
    case 'FINISH_FETCH': {
      return {
        ...state,
        loading: false,
        error: false,
        patient: action.patient
      };
    }
    case 'ERROR_FETCH': {
      return {
        ...state,
        error: true,
        loading: false
      };
    }
    case 'SET_VALID':
      return {
        ...state,
        valid: action.payload
      };
    case 'SET_PATIENT':
      return {
        ...state,
        patient: action.patient
      };
    case 'SET_STATUS':
      return {
        ...state,
        status: action.status
      };

    default:
      throw new Error();
  }
};

export default patientReducerSked;
