// La forma de estructurar cada reducer, es solo una convencion llamada redux ducks.
// Mas detalles en su pagina de github
// https://github.com/erikras/ducks-modular-redux

export type PayloadObject = {
  label: string;
  value: string;
  other?: any;
};

export interface State {
  area: PayloadObject;
  plan: PayloadObject;
  location: PayloadObject;
  service: PayloadObject;
  doctor: PayloadObject;
  selectedBlock: PayloadObject;
  specialty: PayloadObject;
  office: PayloadObject;
}

export interface Payload {
  key: keyof State;
  data: PayloadObject;
}

export interface SetterType {
  type: string;
  payload: Payload;
}

// Default value of store
export const initialState = {
  area: { label: '', value: '' },
  plan: { label: '', value: '' },
  location: { label: '', value: '' },
  service: { label: '', value: '' },
  doctor: { label: '', value: '' },
  selectedBlock: { label: '', value: '' },
  specialty: { label: '', value: '' },
  office: { label: '', value: '' }
} as State;

// Action Types
export const SET = 'agenda-web/flow/SET';

// actions
export type ActionTypes = { type: typeof SET; payload: Payload };

export const setFlow = (key: keyof State, data: PayloadObject): ActionTypes => {
  const payload = { key, data };
  return { type: SET, payload };
};

// Reducer
const flowReducer = (state: State = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case SET: {
      const { key, data } = action.payload;
      return {
        ...state,
        [key]: data
      };
    }
    default:
      return state;
  }
};

export default flowReducer;
