import { createStore, combineReducers, applyMiddleware, Action, AnyAction, Reducer } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import patientsReducer from './reducers/patientsReducer';
import flowReducer from './reducers/flowReducer';
import slotsReducer from './reducers/slotsReducer';

const combinedReducers = combineReducers({
  flowReducer: flowReducer,
  patientsReducer,
  slotsReducer
});

export type RootStore = ReturnType<typeof combinedReducers>;
export type MyThunkDispatch = ThunkDispatch<RootStore, undefined, Action>;

const rootReducer: Reducer = (state: RootStore, action: AnyAction) => {
  if (action.type === 'RESET_APP') {
    state = {} as RootStore;
  }

  return combinedReducers(state, action);
};
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
