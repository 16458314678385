// Definitions
import { HttpClient } from '../http.client';
import { Specialist } from '../../../../domain/models';
import endpoints from '../endpoints';
import defaultParameter from './params.default';

type PropsGetSpecialists = {
  coverageProvider?: string;
  healthcareService?: string;
  specialty?: string;
  practitionerId?: string;
  limit?: number;
};

class PractitionerRoleRepository extends HttpClient {
  constructor(resourceUrl: string) {
    super();
    this.addEndpointBase(resourceUrl);
  }

  async getSpecialists(props?: PropsGetSpecialists): Promise<Specialist> {
    const params: PropsGetSpecialists = Object.assign(defaultParameter, props);
    return this.get(`/list`, { params, headers: {
      Authorization: sessionStorage.getItem('token')
    } });
  }
}

export const specialistApi = new PractitionerRoleRepository(endpoints.practitionerRoles);
