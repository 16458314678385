import plans from './repositories/organization';
import patients from './repositories/patients';
import { specialistApi } from './repositories/practitioner.role';
import specialty from './repositories/specialty';
import offices from './repositories/healthcare.services';
import slots from './repositories/slots';
import appointments from './repositories/appointments';

export default {
  appointmentsService: appointments,
  plansService: plans,
  patientsService: patients,
  specialistService: specialistApi,
  specialtyService: specialty,
  officeService: offices,
  slotsService: slots
};
