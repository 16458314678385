import { useEffect, useReducer } from 'react';
import flowReducerSked from './flowReducerSked';
import { State, PayloadObject } from './flowDefinitionsSked';
// Definitions

// Default value of store
export const defaultValues = {
  area: { label: '', value: '' },
  plan: { label: '', value: '' },
  location: { label: '', value: '' },
  service: { label: '', value: '' },
  doctor: { label: '', value: '' },
  selectedBlock: { label: '', value: '' },
  specialty: { label: '', value: '' },
  office: { label: '', value: '' }
} as State;
// const initialState = localStorage.getItem('sked-flow')
//   ? JSON.parse(localStorage.getItem('sked-flow') as string)
//   : defaultValues;

const useFlowStoreSked = () => {
  const [state, dispatch] = useReducer(flowReducerSked, defaultValues);
  // local storage
  // useEffect(() => {
  //   localStorage.setItem('sked-flow', JSON.stringify(state));
  // }, [state]);
  // actions;
  const set = (key: keyof State, data: PayloadObject) => {
    const obj = { key, data };
    dispatch({ type: 'SET', payload: obj });
  };
  const get = (key: keyof State) => {
    return state[key];
  };
  const areFilled = (keys: (keyof State)[]): boolean => {
    return keys.every((key) => {
      return state[key].value !== '';
    });
  };
  return { state, set, get, areFilled };
};

export default useFlowStoreSked;
