import React from 'react';
import { isSkedClient } from '../domain';
// Infrastructure
import { CookiesProvider } from 'react-cookie';
import Router from '../infrastructure/router';
import ThemeProvider from '../infrastructure/provider/UseTheme';
import StoreProvider from '../infrastructure/provider/UseStore';
import SkedStoreProvider from '../infrastructure/provider/UseSkedStore';
import { AuthProvider } from '../infrastructure/provider/UseAuth';
import { PatientSkedProvider } from '../infrastructure/provider/UseSkedPatient';
import { Provider as ReduxProvider } from 'react-redux';
import store from '../redux/store';

import { SnackbarProvider } from 'notistack';

const App: React.FC = () => {
  const skedProviders = (
    <ReduxProvider store={store}>
      <SnackbarProvider maxSnack={3}>
        <PatientSkedProvider>
          <ThemeProvider>
            <SkedStoreProvider>
              <Router />
            </SkedStoreProvider>
          </ThemeProvider>
        </PatientSkedProvider>
      </SnackbarProvider>
    </ReduxProvider>
  );
  const defaultProviders = (
    <CookiesProvider>
      <AuthProvider>
        <ThemeProvider>
          <StoreProvider>
            <Router />
          </StoreProvider>
        </ThemeProvider>
      </AuthProvider>
    </CookiesProvider>
  );
  if (isSkedClient()) {
    return skedProviders;
  }
  return defaultProviders;
};

export default App;
