// Client
import { httpClient, token } from '../client';

// Definitions
import { AxiosResponse, CancelTokenSource } from 'axios';
import { Patient, VerifyRutResult, PatientResponse } from '../../../../domain/models/sked';
import { FindPatientProps } from '../../../context/sked/patient/patientDefinitionsSked';

// Resource
const resource = '/patients';

// Dynamic token source
let source: CancelTokenSource;

// Definitions
export type UpdatePatientBody = {
  op: string;
  path: string;
  value: string;
};

type PlanId = {
  Id: string;
};

export type CreatePatientBody = {
  firstName1?: string;
  lastName1?: string;
  documentNumber?: string;
  documentCountry?: string;
  documentType?: string;
  gender?: string;
  birthDate?: string;
  mainPhoneNumber?: string;
  coveragePlans?: PlanId[];
  anonimized?: boolean;
};

export default {
  cancel: (): void => source?.cancel('Service cancelled'),
  async verifyRut(props: FindPatientProps): Promise<AxiosResponse<VerifyRutResult>> {
    const { rut, token } = props;
    return await httpClient.post(`${resource}/rut/check`, {
      documentCountry: 'CL',
      documentType: 'NationalId',
      documentValue: rut,
      token
    });
  },
  async create(data: CreatePatientBody): Promise<AxiosResponse<Patient>> {
    const token = sessionStorage.getItem('token')
    return await httpClient.post(`${resource}`, data, {
      headers: {
        Authorization: token
      }
    });
  },
  async update(id: string, data: UpdatePatientBody[]): Promise<AxiosResponse<Patient>> {
    const token = sessionStorage.getItem('token')
    return await httpClient.patch(`${resource}/${id}`, data, {
      headers: {
        Authorization: token
      }
    });
  },
  async getOne(patientId: string): Promise<AxiosResponse<PatientResponse>> {
    const token = sessionStorage.getItem('token')
    return await httpClient.get(`${resource}/${patientId}`, {
      headers: {
        Authorization: token
      }
    });
  }
};
