import React, { createContext, useContext } from 'react';

// Stores
import useFlowStore, { FlowTypeData, FlowData } from '../../store/FlowStore';
import useUserStore, { UserTypeData, UserData } from '../../store/UserStore';
import useSkedClientStore, {
  GetLocationProps,
  GetServicesProps,
  GetDoctorsProps
} from '../../store/SkedClientStore';

import useClientStore, {
  GetWeekSlotsProps,
  GetSpecialistProps,
  GetPlansProps,
  GetHealthcareServiceProps,
  SlotResultData,
  OptionData
} from '../../store/ClientStore';
import useFlowStoreSked, { defaultValues } from '../../context/sked/flow/flowStoreSked';
import { State, PayloadObject } from '../../context/sked/flow/flowDefinitionsSked';

// Export store definitions
export type FTD = FlowTypeData;
export type FD = FlowData;
export type SRD = SlotResultData;
export type OD = OptionData;
export type UTD = UserTypeData;
export type UD = UserData;

// Definitions
type Provider = {
  methods: {
    client: {
      getPlans: (props?: GetPlansProps) => Promise<OptionData[]>;
      getHealthcareServices: (props?: GetHealthcareServiceProps) => Promise<OptionData[]>;
      getSpecialties: () => Promise<OptionData[]>;
      getSpecialists: (props: GetSpecialistProps) => Promise<OptionData[]>;
      getWeekSlots: (props: GetWeekSlotsProps) => Promise<SlotResultData[]>;
    };
    skedClient: {
      getDoctors: (props: GetDoctorsProps) => Promise<OptionData[]>;
      getServices: (props: GetServicesProps) => Promise<OptionData[]>;
      getLocations: (props: GetLocationProps) => Promise<OptionData[]>;
      getAreas: () => Promise<OptionData[]>;
      getPlans: (props?: GetPlansProps) => Promise<OptionData[]>;
    };
    user: {
      save: (type: keyof UserData, data?: string | UserTypeData) => string | UserTypeData;
      get: (type: keyof UserData) => string | UserTypeData;
      getAll: () => UserData;
    };
    flow: {
      get: (type: keyof FlowData) => FlowTypeData;
      save: (type: keyof FlowData, data: FlowTypeData) => void;
      getAll: () => FlowData;
    };
    flowSked: {
      state: State;
      set: (key: keyof State, data: PayloadObject) => void;
      get: (type: keyof State) => PayloadObject;
      areFilled: (keys: (keyof State)[]) => boolean;
    };
  };
};

// Default values
const defaultValue = {
  methods: {
    client: {
      getPlans: async (props?: GetPlansProps) => [],
      getHealthcareServices: async (props?: GetHealthcareServiceProps) => [],
      getSpecialties: async () => [],
      getSpecialists: async (props: GetSpecialistProps) => [],
      getWeekSlots: async (props: GetWeekSlotsProps) => []
    },
    skedClient: {
      getLocations: async (props: GetLocationProps) => [],
      getServices: async (props: GetServicesProps) => [],
      getDoctors: async (props: GetDoctorsProps) => [],
      getAreas: async () => [],
      getPlans: async (props?: GetPlansProps) => []
    },
    user: {
      save: (type: keyof UserData, data?: string | UserTypeData) => '' || ({} as UserTypeData),
      get: (type: keyof UserData) => '' || ({} as UserTypeData),
      getAll: () => ({} as UserData)
    },
    flow: {
      get: (type: keyof FlowData) => ({} as FlowTypeData),
      save: (type: keyof FlowData, data: FlowTypeData) => {},
      getAll: () => ({} as FlowData)
    },
    flowSked: {
      state: defaultValues,
      set: (key: keyof State, data: PayloadObject) => {},
      get: (type: keyof State) => ({} as PayloadObject),
      areFilled: (keys: (keyof State)[]) => false
    }
  }
};

/**
 * Provider
 */
const Provider = (): Provider => {
  const flowStore = useFlowStore();
  const flowStoreSked = useFlowStoreSked();
  const clientStore = useClientStore();
  const skedClientStore = useSkedClientStore();
  const userStore = useUserStore();

  const methods = {
    client: clientStore,
    skedClient: skedClientStore,
    user: userStore,
    flow: flowStore,
    flowSked: flowStoreSked
  };
  return { methods };
};

/**
 * Context
 */
const Context = createContext<Provider>(defaultValue);

/**
 * Component provider
 * @param children
 * @constructor
 */
const StoreProvider = ({ children }: any) => {
  const store = Provider();
  return <Context.Provider value={store}>{children}</Context.Provider>;
};

/**
 * Component hook
 */
const useStore = () => {
  return useContext(Context);
};

export { StoreProvider, useStore };
