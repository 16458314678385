import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios';
import {api} from '../../../domain/client.json';

export class HttpClient {
  private axios: AxiosInstance;

  protected constructor() {
    this.axios = axios.create({ baseURL: api.baseURL, headers: api.headers });
    this._initializeConfiguration();
  }

  private _initializeConfiguration(): void {
    this.axios.interceptors.response.use(
      (response: AxiosResponse) => {
        return response.data;
      },
      (error) => Promise.reject(error)
    );
  }

  protected addEndpointBase(endpointBase = ''): HttpClient {
    this.axios = axios.create({ baseURL: api.baseURL + endpointBase, headers: api.headers });
    this._initializeConfiguration();
    return this;
  }

  public getUri(config?: AxiosRequestConfig): string {
    return this.axios.getUri(config);
  }

  public request<T, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.axios.request(config);
  }

  public get<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.axios.get(url, config);
  }

  public delete<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.axios.delete(url, config);
  }

  public head<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.axios.head(url, config);
  }

  public post<T, B, R = AxiosResponse<T>>(
    url: string,
    data?: B,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.axios.post(url, data, config);
  }

  public put<T, B, R = AxiosResponse<T>>(
    url: string,
    data?: B,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.axios.put(url, data, config);
  }

  public patch<T, B, R = AxiosResponse<T>>(
    url: string,
    data?: B,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.axios.patch(url, data, config);
  }

  public success<T>(response: AxiosResponse<T>): T {
    return response.data;
  }

  public error(error: AxiosError<Error>) {
    throw error;
  }
}
