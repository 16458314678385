// Definitions
import { HttpClient } from '../http.client';
import endpoints from '../endpoints';
import {
  GetInfoUserResponse,
  LoginResponse
} from '../../../../domain/models/api/security.response';

type LoginProp = {
  username: string;
  password: string;
};

class SecurityRepository extends HttpClient {
  constructor(resourceUrl: string) {
    super();
    this.addEndpointBase(resourceUrl);
  }

  async login(credentials: LoginProp): Promise<LoginResponse> {
    return this.post('/login', { credentials });
  }

  async getInfoUser(authorization: string): Promise<GetInfoUserResponse> {
    return this.get('/api/users/me', { headers: { authorization } });
  }
}

export default new SecurityRepository(endpoints.empty);
