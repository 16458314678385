import Patient from '../../../../domain/models/sked/Patient';

export type InnerAction =
  | { type: 'START_FETCH' }
  | { type: 'FINISH_FETCH'; patient?: Patient }
  | { type: 'ERROR_FETCH' };

export type OuterAction =
  | { type: 'SET_VALID'; payload?: boolean }
  | { type: 'SET_PATIENT'; patient?: Patient }
  | { type: 'SET_STATUS'; status: Status };

export type Action = InnerAction | OuterAction;

export enum Status {
  Updating = 'updating',
  Creating = 'creating'
}
export interface State {
  patient?: Patient;
  loading: boolean;
  error?: boolean;
  valid?: boolean;
  status: Status;
}

export interface HttpResponseStructure {
  message?: string;
  status?: number;
}
export interface FindPatientProps {
  rut: string;
  token: string;
}
