import React from 'react';
import Lottie, { Options, EventListener } from 'react-lottie';
import { finder } from '../../../animations';

// Definitions
type Props = {
  name: string;
  loop?: boolean;
  autoplay?: boolean;
  title?: string;
  height?: number | string;
  width?: number | string;
  style?: React.CSSProperties;
  speed?: number;
  direction?: number;
  isPaused?: boolean;
  isStopped?: boolean;
  ariaLabel?: string;
  ariaRole?: string;
  isClickToPauseDisabled?: boolean;
  eventListeners?: ReadonlyArray<EventListener>;
  options?: Options;
  segments?: ReadonlyArray<number>;
};

const Animation = (props: Props) => {
  const {
    name,
    loop = false,
    autoplay = false,
    title,
    height,
    width,
    style,
    speed,
    direction,
    isPaused,
    isStopped,
    ariaLabel,
    ariaRole,
    isClickToPauseDisabled,
    eventListeners,
    options: customOptions = {},
    segments
  } = props;

  // Dynamic Import
  const data = finder(name);

  /*
   * Lottie options
   * Docs: https://github.com/airbnb/lottie-web
   */
  const options = {
    ...{
      loop,
      autoplay,
      animationData: data,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    },
    ...customOptions
  };

  return (
    <>
      {data && (
        <Lottie
          options={options}
          height={height}
          width={width}
          title={title}
          style={style}
          speed={speed}
          segments={segments}
          direction={direction}
          isPaused={isPaused}
          isStopped={isStopped}
          ariaRole={ariaRole}
          ariaLabel={ariaLabel}
          eventListeners={eventListeners}
          isClickToPauseDisabled={isClickToPauseDisabled}
        />
      )}
      {!data && name && <div>Animation not found</div>}
    </>
  );
};

export default Animation;
