// Definitions
import { HttpClient } from '../http.client';
import endpoints from '../endpoints';
import Area from '../../../../domain/models/sked/Area';

type Props = {
  searchPhrase?: string | null;
  includeAvailabilities?: boolean;
  coveragePlanId?: string | null;
  locationId?: string | null;
  count?: boolean;
  channel?: string;
  top?: number;
};

const defaults = {
  searchPhrase: null,
  includeAvailabilities: true,
  coveragePlanId: null,
  locationId: null,
  count: true,
  channel: 'PatientPortal',
  top: 10
} as Props;

class CoveragePlanRepository extends HttpClient {
  constructor(resourceUrl: string) {
    super();
    this.addEndpointBase(resourceUrl);
  }

  async getAreas(body: Props): Promise<Area[]> {
    const data = Object.assign(defaults, body);
    return this.post('', data, {
      headers: {
        Authorization: sessionStorage.getItem('token')
      } 
    });
  }
}

export default new CoveragePlanRepository(endpoints.areas);
