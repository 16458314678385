import { createState, useState, self } from '@hookstate/core';

// Services
import Med360 from '../services/med360';

// Definitions
import { Option } from '../../presentation/components/atoms/Autocomplete';
import { Result as SpecialistResult } from '../../domain/models/Specialist';
import { Result as SlotResult } from '../../domain/models/Slots';
export type SlotResultData = SlotResult;
export type OptionData = Option;

export type ClientData = {
  plans: Option[];
  offices: Option[];
  specialties: Option[];
  specialists: SpecialistResult[];
  slots: SlotResult[];
};

const defaultValue = {
  plans: [],
  offices: [],
  specialties: [],
  specialists: [],
  slots: []
};

export type GetPlansProps = {
  planIds: string[];
  clientPrefix?: string;
};

export type GetHealthcareServiceProps = {
  officeIds: string[];
};

export type GetSpecialistProps = {
  planId?: string;
  officeId?: string;
  specialistId?: string;
};

export type GetWeekSlotsProps = {
  from: Date;
  to: Date;
  planId?: string;
  officeId?: string;
  specialtyId?: string;
  specialistId?: string;
};

/**
 * Hook
 */
const useClientStore = createState<ClientData>(defaultValue)[self].map((currentState) => () => {
  const state = useState(currentState);
  const getValueIsDifferentAllOrUndefined = (value?: string): string | undefined => {
    if (value !== 'ALL') return value;

    return undefined;
  };
  return {
    async getPlans(props?: GetPlansProps): Promise<Option[]> {
      if (state.plans[self].get().length === 0) {
        const { plansService: service } = Med360;
        const { result } = await service.getPlans();
        const plans = result.map((data) => {
          const { name, id } = data;
          return { label: name.trim(), value: id.toString().trim() };
        });
        // Add default value
        plans.push({
          label: 'TODAS LAS PREVISIONES',
          value: 'ALL'
        });
        state.plans[self].set(plans);
      }
      // Filters
      if (props) {
        const { planIds } = props;
        return state.plans[self].get().filter((plan) => planIds.includes(plan.value));
      }
      return JSON.parse(JSON.stringify(state.plans[self].get()));
    },
    async getHealthcareServices(props?: GetHealthcareServiceProps): Promise<Option[]> {
      if (state.offices[self].get().length === 0) {
        const { officeService: service } = Med360;
        const { result } = await service.getHealthcareServices();
        const offices = result.map((data) => {
          const { name, id, location: locationInfo } = data;
          const { text: location, city } = locationInfo[0]?.address;
          return {
            label: name.trim(),
            value: id.toString().trim(),
            other: { location, city }
          };
        });
        // Add default value
        offices.push({
          label: 'TODAS LAS SUCURSALES',
          value: 'ALL',
          other: { location: 'Sin definir', city: 'Sin definir' }
        });
        state.offices[self].set(offices);
      }
      return JSON.parse(JSON.stringify(state.offices[self].get())).filter((office: Option) => {
        if (props) return props.officeIds.includes(office.value);
        return office;
      });
    },
    async getSpecialties(): Promise<Option[]> {
      if (state.specialties[self].get().length === 0) {
        const { specialtyService: service } = Med360;
        const { result } = await service.getSpecialties();
        const specialties = result.map((data) => {
          const { display, id } = data.coding[0];
          return { label: display.trim(), value: id.toString().trim() };
        });
        if (specialties.length > 0) state.specialties[self].set(specialties);
      }
      return JSON.parse(JSON.stringify(state.specialties[self].get()));
    },
    async getSpecialists(props: GetSpecialistProps): Promise<Option[]> {
      // Props
      const { planId = 'ALL', officeId = 'ALL', specialistId = 'ALL' } = props;

      // Get specialist original data
      if (state.specialists[self].get().length === 0) {
        const { specialistService: service } = Med360;
        const { result } = await service.getSpecialists();
        if (result) {
          state.specialists[self].set(result);
        }
      }

      return state.specialists[self]
        .get()
        .filter((specialist) => {
          if (planId !== 'ALL' && officeId !== 'ALL' && specialistId !== 'ALL') {
            return (
              specialist.coverageProvidersId?.split(',').includes(planId) &&
              specialist.healthcareServiceId === officeId &&
              specialist.practitionerId === specialistId
            );
          }
          if (planId !== 'ALL') {
            return specialist.coverageProvidersId?.split(',').includes(planId);
          }
          if (officeId !== 'ALL') {
            return specialist.healthcareServiceId === officeId;
          }
          if (specialistId !== 'ALL') {
            return specialist.practitionerId === specialistId;
          }
          return specialist;
        })
        .reduce((collection, data) => {
          const {
            practitionerName,
            practitionerId,
            specialtyId,
            healthcareServiceId,
            coverageProvidersId
          } = data;

          if (!collection.find((item) => item.value === practitionerId)) {
            collection.push({
              label: practitionerName.trim(),
              value: practitionerId.toString().trim(),
              other: {
                specialtyId,
                healthcareServiceId,
                coverageProvidersId
              }
            });
          }

          return collection;
        }, [] as Option[]);
    },
    async getWeekSlots(props: GetWeekSlotsProps): Promise<SlotResult[]> {
      const {
        from,
        to,
        planId = 'ALL',
        officeId = 'ALL',
        specialtyId = 'ALL',
        specialistId = 'ALL'
      } = props;

      // Get slots original data
      const { slotsService: service } = Med360;
      const { result } = await service.getAvailablesSlots({
        from,
        to,
        coverageProvider: getValueIsDifferentAllOrUndefined(planId),
        healthcareService: getValueIsDifferentAllOrUndefined(officeId),
        specialty: getValueIsDifferentAllOrUndefined(specialtyId),
        practitionerId: getValueIsDifferentAllOrUndefined(specialistId)
      });
      if (result) state.slots[self].set(result);

      return JSON.parse(JSON.stringify(state.slots[self].get()));
    }
  };
});

export default useClientStore;
