const resources = {
  practitionerRoles: '/api/practitionerRoles',
  healthcareServicesSpecialties: '/api/healthcareServicesSpecialties',
  healthcareServices: '/api/healthcareServices',
  organizations: '/api/organizations',
  base: '/',
  empty: '',
  api: '/api'
};
export default resources;
