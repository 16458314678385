// Client
import { httpClient, token } from '../client';
import { format, parseISO } from 'date-fns';

// Definitions
import { AxiosResponse, CancelTokenSource } from 'axios';
import Location from '../../../../domain/models/sked/Location';
import Region from '../../../../domain/models/sked/Region';

// Resource
const resource = '/search/locations';

const defaultParams = {
  areaId: null,
  count: true,
  exclusionList: [],
  includeAvailabilities: true,
  onlyAssignedToUser: false,
  resourceId: null,
  searchPhrase: '',
  serviceId: null
};

interface Props {
  areaId?: string | null;
  count?: boolean;
  exclusionList?: string[];
  includeAvailabilities?: boolean;
  onlyAssignedToUser?: boolean;
  resourceId?: string | null;
  serviceId?: string | null;
  searchPhrase?: string;
}

// Dynamic token source
let source: CancelTokenSource;

export default {
  async get(props: Props): Promise<AxiosResponse<Region[]>> {
    const tokenApi = sessionStorage.getItem('token')
    source = token.source();
    const params = Object.assign(defaultParams, props);
    return httpClient.post(`${resource}`, params, { cancelToken: source.token, headers: {
      Authorization: tokenApi
    } });
  }
};
