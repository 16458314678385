import { createState, useState, self } from '@hookstate/core';

// Services
import Sked from '../services/sked';

// Definitions
import { Option } from '../../presentation/components/atoms/Autocomplete';
import { Result as SpecialistResult } from '../../domain/models/Specialist';
import { Result as SlotResult } from '../../domain/models/Slots';
import { Props as GDP } from '../services/sked/repositories/doctors';

export type GetDoctorsProps = GDP;

export type SlotResultData = SlotResult;
export type OptionData = Option;

export type ClientData = {
  doctors: Option[];
  services: Option[];
  locations: Option[];
  areas: Option[];
  plans: Option[];
  offices: Option[];
  specialties: Option[];
  specialists: SpecialistResult[];
  slots: SlotResult[];
};

const defaultValue = {
  locations: [],
  services: [],
  doctors: [],
  areas: [],
  plans: [],
  offices: [],
  specialties: [],
  specialists: [],
  slots: []
};

export type GetPlansProps = {
  planIds: string[];
};

export type GetLocationProps = {
  areaId?: string | null;
  forceRequest?: boolean;
};

export type GetServicesProps = {
  areaId: string;
  coveragePlanId: string;
  locationId: string;
  patientId?: string | null;
  resourceId?: string | null;
  forceRequest?: boolean;
};

export type GetHealthcareServiceProps = {
  officeIds: string[];
};

export type GetSpecialistProps = {
  planId?: string;
  officeId?: string;
  specialistId?: string;
};

export type GetWeekSlotsProps = {
  from: Date;
  to: Date;
  planId?: string;
  officeId?: string;
  specialtyId?: string;
  specialistId?: string;
};

/**
 * Hook
 */
const useSkedClientStore = createState<ClientData>(defaultValue)[self].map((currentState) => () => {
  const state = useState(currentState);
  const getValueIsDifferentAllOrUndefined = (value?: string): string | undefined => {
    if (value !== 'ALL') return value;

    return undefined;
  };
  return {
    async getDoctors(props: GetDoctorsProps): Promise<Option[]> {
      const { areaId, coveragePlanId, locationId, forceRequest } = props;
      if (state.services[self].get().length === 0 || forceRequest) {
        const { doctorsService: service } = Sked;
        const { data: result } = await service.get({
          areaId,
          coveragePlanId,
          locationId,
          forceRequest
        });
        const doctors = result.map((data) => {
          const { name, resourceId: id } = data;
          return { label: name.trim(), value: id.toString().trim() };
        });
        state.doctors[self].set(doctors);
      }
      return JSON.parse(JSON.stringify(state.doctors[self].get()));
    },
    async getServices(props: GetServicesProps): Promise<Option[]> {
      const fakeServices = [
        {
          name: 'Consulta Medica',
          serviceId: '18d155fd-0460-4145-84f9-aaab002e591b'
        }
      ];
      const defaultParams = {
        patientId: null,
        resourceId: '',
        forceRequest: false
      };
      const {
        areaId,
        coveragePlanId,
        locationId,
        patientId,
        forceRequest,
        resourceId
      } = Object.assign(defaultParams, props);
      if (state.services[self].get().length === 0 || forceRequest) {
        const { servicesService: service } = Sked;
        const { data: result } = await service.get({
          areaId,
          coveragePlanId,
          locationId,
          resourceId,
          patientId
        });
        const services = result.map((data) => {
          const { specialityName, serviceId: id } = data;
          return { label: specialityName.trim(), value: id.toString().trim() };
        });
        state.services[self].set(services);
      }
      return JSON.parse(JSON.stringify(state.services[self].get()));
    },
    async getSpecialties(props: GetServicesProps): Promise<Option[]> {
      const fakeServices = [
        {
          name: 'Consulta Medica',
          serviceId: '18d155fd-0460-4145-84f9-aaab002e591b'
        }
      ];
      const defaultParams = {
        patientId: null,
        resourceId: '',
        forceRequest: false
      };
      const {
        areaId,
        coveragePlanId,
        locationId,
        patientId,
        forceRequest,
        resourceId
      } = Object.assign(defaultParams, props);
      if (state.specialties[self].get().length === 0 || forceRequest) {
        const { servicesService: service } = Sked;
        const { data: result } = await service.get({
          areaId,
          coveragePlanId,
          locationId,
          resourceId,
          patientId
        });
        const specialties = result.map((data) => {
          const { specialityName: name, specialityId: id, name: serviceName, serviceId } = data;
          return {
            label: name.trim(),
            value: id.toString().trim(),
            other: { serviceId, serviceName }
          };
        });
        state.specialties[self].set(specialties);
      }
      return JSON.parse(JSON.stringify(state.specialties[self].get()));
    },

    async getLocations(props: GetLocationProps): Promise<Option[]> {
      const defaultParams = {
        areaId: null,
        forceRequest: false
      };

      const { areaId, forceRequest } = Object.assign(defaultParams, props);

      if (state.locations[self].get().length === 0 || forceRequest) {
        const { locationsService: service } = Sked;
        const { data: regions } = await service.get({ areaId });
        const locations = regions
          .map((r) => {
            return r.locations.map((l) => {
              return { label: l.name.trim(), value: l.locationId.toString().trim() };
            });
          })
          .reduce((acc, val) => acc.concat(val), []);

        // // Add default value
        // plans.push({
        //   label: 'TODAS LAS PREVISIONES',
        //   value: 'ALL'
        // });
        state.locations[self].set(locations);
      }
      return JSON.parse(JSON.stringify(state.locations[self].get()));
    },
    async getAreas(): Promise<Option[]> {
      if (state.areas[self].get().length === 0) {
        const { areasService: service } = Sked;
        const result = await service.getAreas({});
        const areas = result.map((data) => {
          const { Name, AreaId } = data;
          return { label: Name.trim(), value: AreaId.toString().trim() };
        });
        // // Add default value
        // plans.push({
        //   label: 'TODAS LAS PREVISIONES',
        //   value: 'ALL'
        // });
        state.areas[self].set(areas);
      }
      return JSON.parse(JSON.stringify(state.areas[self].get()));
    },
    async getPlans(props?: GetPlansProps): Promise<Option[]> {
      if (state.plans[self].get().length === 0) {
        const { plansService: service } = Sked;
        const result = await service.getPlans();
        const plans = result.map((data) => {
          const { name, id, isPrivate } = data;
          return { label: name.trim(), value: id.toString().trim(), other: {isPrivate} };
        });
        // // Add default value
        // plans.push({
        //   label: 'TODAS LAS PREVISIONES',
        //   value: 'ALL'
        // });
        state.plans[self].set(plans);
      }
      // Filters
      if (props) {
        const { planIds } = props;
        return state.plans[self].get().filter((plan) => planIds.includes(plan.value));
      }
      return JSON.parse(JSON.stringify(state.plans[self].get()));
    }
  };
});

export default useSkedClientStore;
