// Definitions
import { HttpClient } from '../http.client';
import endpoints from '../endpoints';
import Specialty from '../../../../domain/models/Specialty';

type PropsGetSpecialties = {
  limit?: number;
};

class HealthcareServicesSpecialtiesRepository extends HttpClient {
  constructor(resourceUrl: string) {
    super();
    this.addEndpointBase(resourceUrl);
  }

  async getSpecialties(): Promise<Specialty> {
    const params: PropsGetSpecialties = Object.assign({ limit: 1000 });
    return this.get('', { params, headers: {
      Authorization: sessionStorage.getItem('token')
    } });
  }
}

export default new HealthcareServicesSpecialtiesRepository(endpoints.healthcareServicesSpecialties);
