// Definitions
import { HttpClient } from '../http.client';
import endpoints from '../endpoints';
import CoveragePlan from '../../../../domain/models/sked/CoveragePlan';
import defaultParameter from './params.default';

type PropsGetPlans = {
  limit?: number;
};

class CoveragePlanRepository extends HttpClient {
  constructor(resourceUrl: string) {
    super();
    this.addEndpointBase(resourceUrl);
  }

  async getPlans(): Promise<CoveragePlan[]> {
    const params: PropsGetPlans = defaultParameter;
    return this.get(``, { params, headers: {
      Authorization: sessionStorage.getItem('token')
    } });
  }
}

export default new CoveragePlanRepository(endpoints.coveragePlans);
