import SlotsByLocation from '../../../../domain/models/sked/slots';
import { HttpClient } from '../http.client';
import endpoints from '../endpoints';

import { formatISO } from 'date-fns';

//--- Definitions
type PropsGetSlots = {
  dateTimeFrom: Date;
  dateTimeTo: Date;
  serviceId: string;
  startingCenterId: string;
  coveragePlanId: string;
  distance?: number;
  adjacentSlots?: number;
  channel?: string;
  includeSelfPayer?: boolean;
  includeNotBookable?: boolean;
  patientId?: string;
  resourceId?: string;
};

type PropsGetObjectDetails = {
  resourceId: string;
  centerId: string;
  serviceId: string;
  channel?: string;
};

export type ObjectDetailsResponse = {
  ObjectDetail: {
    Id: string;
    Name: string;
    Content: string;
    MustShow: boolean;
    SendByEmail: boolean;
  };
  AppliesTo: {
    Center: boolean;
    Service: boolean;
    Resource: boolean;
    SubService: boolean;
  };
};

// --- The Class
class SlotRepository extends HttpClient {
  constructor(resourceUrl: string) {
    super();
    this.addEndpointBase(resourceUrl);
  }

  async getSlotsByLocation(props: PropsGetSlots): Promise<SlotsByLocation> {
    const defaultParams = {
      adjacentSlots: 1,
      includeSelfPayer: false,
      includeNotBookable: false,
      distance: 5
    };
    const formattedProps = {
      ...props,
      dateTimeFrom: formatISO(props.dateTimeFrom),
      dateTimeTo: formatISO(props.dateTimeTo)
    };
    const params = Object.assign(defaultParams, formattedProps);
    return this.post(`/by-location`, params, {
      headers: {
        Authorization: sessionStorage.getItem('token')
      }
    });
  }
  async getObjectDetails(props: PropsGetObjectDetails): Promise<ObjectDetailsResponse[]> {
    const defaultParams = {
      channel: 'PatientPortal'
    };
    const params = Object.assign(defaultParams, props);
    return this.post(`/get-object-details`, params, {
      headers: {
        Authorization: sessionStorage.getItem('token')
      }
    });
  }
}

export default new SlotRepository(endpoints.slots);
