import areas from './repositories/areas';
import plans from './repositories/organization';
import locations from './repositories/locations';
import services from './repositories/services';
import doctors from './repositories/doctors';

import patients from './repositories/patients';
import { specialistApi } from './repositories/practitioner.role';
import specialty from './repositories/specialty';
import offices from './repositories/healthcare.services';
import slots from './repositories/slots';
import appointments from './repositories/appointments';

export default {
  appointmentsService: appointments,
  locationsService: locations,
  servicesService: services,
  doctorsService: doctors,
  plansService: plans,
  areasService: areas,
  patientsService: patients,
  specialistService: specialistApi,
  specialtyService: specialty,
  officeService: offices,
  slotsService: slots
};
