import { State, Action } from './flowDefinitionsSked';
const fn = (state: State, action: Action) => {
  const { key, data } = action.payload;
  switch (action.type) {
    case 'SET':
      return {
        ...state,
        [key]: data
      };
    default:
      throw new Error();
  }
};

export default fn;
