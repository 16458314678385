import { createState, useState, self } from '@hookstate/core';

// Definitions
export type UserTypeData = {
  label: string;
  value: string;
  planIds?: string[];
};

export type UserData = {
  id: string;
  dni: string;
  firstName: string;
  fatherLastName: string;
  motherLastName: string;
  birthDate: string; // ISO-8601 FORMAT
  plan: UserTypeData;
  email: string;
  phoneNumber: string;
  contactMethod: string;
  gender: string;
};

// Default value of store
const defaultValue = {
  id: '',
  dni: '',
  firstName: '',
  fatherLastName: '',
  motherLastName: '',
  birthDate: '', // ISO-8601
  plan: { label: '', value: '', planIds: [] },
  email: '',
  phoneNumber: '',
  contactMethod: '',
  gender: ''
};

/**
 * Hook
 */
const useUserStore = createState<UserData>(defaultValue)[self].map((currentState) => () => {
  const state = useState(currentState);
  return {
    save(type: keyof UserData, data?: string | UserTypeData): string | UserTypeData {
      if (type === 'plan') {
        const { label, value, planIds } = data as UserTypeData;
        state[type][self].set({ label, value, planIds });
      } else {
        state[type].set(data as string);
      }
      return data as string | UserTypeData;
    },
    get(type: keyof UserData): string | UserTypeData {
      if (type === 'plan') {
        return JSON.parse(JSON.stringify(state[type][self].get()));
      }
      return state[type].get();
    },
    getAll(): UserData {
      return JSON.parse(JSON.stringify(state[self].get()));
    }
  };
});

export default useUserStore;
