import { createState, useState, self } from '@hookstate/core';

// Definitions
export type FlowTypeData = {
  label: string;
  value: string;
  other?: any;
};

export type FlowData = {
  area?: FlowTypeData;
  plan: FlowTypeData;
  office: FlowTypeData;
  specialty: FlowTypeData;
  specialist: FlowTypeData;
  selectedBlock: FlowTypeData;
  avatar: FlowTypeData;
};

// Default value of store
const defaultValue = {
  plan: { label: '', value: '' },
  office: { label: '', value: '' },
  specialty: { label: '', value: '' },
  specialist: { label: '', value: '' },
  selectedBlock: { label: '', value: '' },
  avatar: { label: '', value: '' }
};

/**
 * Hook
 */
const useFlowStore = createState<FlowData>(defaultValue)[self].map((currentState) => {
  return () => {
    const state = useState(currentState);
    return {
      save(type: keyof FlowData, data: FlowTypeData) {
        const { label, value } = data;
        const customLabel = label.includes('[') ? label : label.replace('-', '');

        state[type][self].set({
          label: customLabel,
          value
        });
      },
      get(type: keyof FlowData): FlowTypeData {
        return JSON.parse(JSON.stringify(state[type][self].get()));
      },
      getAll(): FlowData {
        return JSON.parse(JSON.stringify(state[self].get()));
      }
    };
  };
});

export default useFlowStore;
