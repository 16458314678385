import React, { createContext, useContext } from 'react';

// Stores
import useSkedClientStore, {
  GetLocationProps,
  GetServicesProps,
  GetDoctorsProps,
  GetPlansProps,
  OptionData
} from '../../store/SkedClientStore';

import useFlowStoreSked, { defaultValues } from '../../context/sked/flow/flowStoreSked';
import { State, PayloadObject } from '../../context/sked/flow/flowDefinitionsSked';

// Definitions
type Provider = {
  stores: {
    skedClient: {
      getDoctors: (props: GetDoctorsProps) => Promise<OptionData[]>;
      getServices: (props: GetServicesProps) => Promise<OptionData[]>;
      getSpecialties: (props: GetServicesProps) => Promise<OptionData[]>;
      getLocations: (props: GetLocationProps) => Promise<OptionData[]>;
      getAreas: () => Promise<OptionData[]>;
      getPlans: (props?: GetPlansProps) => Promise<OptionData[]>;
    };
    flowSked: {
      state: State;
      set: (key: keyof State, data: PayloadObject) => void;
      get: (type: keyof State) => PayloadObject;
      areFilled: (keys: (keyof State)[]) => boolean;
    };
  };
};

// Default values
const defaultValue = {
  stores: {
    skedClient: {
      getLocations: async (props: GetLocationProps) => [],
      getServices: async (props: GetServicesProps) => [],
      getSpecialties: async (props: GetServicesProps) => [],
      getDoctors: async (props: GetDoctorsProps) => [],
      getAreas: async () => [],
      getPlans: async (props?: GetPlansProps) => []
    },
    flowSked: {
      state: defaultValues,
      set: (key: keyof State, data: PayloadObject) => {},
      get: (type: keyof State) => ({} as PayloadObject),
      areFilled: (keys: (keyof State)[]) => false
    }
  }
};

/**
 * Provider
 */
const Provider = (): Provider => {
  const flowStoreSked = useFlowStoreSked();
  const skedClientStore = useSkedClientStore();

  const stores = {
    skedClient: skedClientStore,
    flowSked: flowStoreSked
  };
  return { stores };
};

/**
 * Context
 */
const Context = createContext<Provider>(defaultValue);

/**
 * Component provider
 * @param children
 * @constructor
 */
const SkedStoreProvider = ({ children }: any) => {
  const store = Provider();
  return <Context.Provider value={store}>{children}</Context.Provider>;
};

/**
 * Component hook
 */
const UseSkedStore = () => {
  return useContext(Context);
};

export { SkedStoreProvider, UseSkedStore };
