// Definitions
import { HttpClient } from '../http.client';
import endpoints from '../endpoints';
import { Offices } from '../../../../domain/models';
import defaultParameter from './params.default';

type PropGetHealthcareService = {
  limit?: number;
};

class HealthcareServiceRepository extends HttpClient {
  constructor(resourceUrl: string) {
    super();
    this.addEndpointBase(resourceUrl);
  }

  async getHealthcareServices(): Promise<Offices> {
    const params: PropGetHealthcareService = defaultParameter;
    return this.get(``, { params, headers: {
      Authorization: sessionStorage.getItem('token')
    } });
  }
}

export default new HealthcareServiceRepository(endpoints.healthcareServices);
