import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

// Route list
import Page404 from '../../presentation/components/templates/Page404';

// Provider
import { useStore } from '../provider/UseStore';
import { UseSkedStore } from '../provider/UseSkedStore';
import { isSkedClient } from '../../domain';
import { UseSkedPatient } from '../provider/UseSkedPatient';
import { useDispatch, useSelector } from 'react-redux';
import store, { RootStore, MyThunkDispatch } from '../../redux/store';
import { State as FlowState } from '../../redux/reducers/flowReducer';
import Animation from '../../presentation/components/atoms/Animation';
import { Fade } from '@material-ui/core';

// Components
// Default (cuc, cbb) Components
const Ingress = lazy(() => import('../../presentation/components/pages/Ingress'));
const Booking = lazy(() => import('../../presentation/components/pages/Booking'));
const Success = lazy(() => import('../../presentation/components/pages/Success'));
const Specialty = lazy(() => import('../../presentation/components/pages/Specialty'));
const Specialist = lazy(() => import('../../presentation/components/pages/Specialist'));
const Reservation = lazy(() => import('../../presentation/components/pages/Reservation'));
// Sked Components
const VerifyRut = lazy(() => import('../../presentation/components/pages/sked/VerifyRut'));
const BookingSked = lazy(() => import('../../presentation/components/pages/sked/Booking'));
const SpecialtySked = lazy(() => import('../../presentation/components/pages/sked/Specialty'));
const Doctor = lazy(() => import('../../presentation/components/pages/sked/Doctor'));
const SkedRegister = lazy(() => import('../../presentation/components/pages/sked/Register'));
const SkedContactability = lazy(() =>
  import('../../presentation/components/pages/sked/Contactability')
);
const SkedSuccess = lazy(() => import('../../presentation/components/pages/sked/Success'));

const DefaultRouter: React.FC = () => {
  const { methods: storeMethods } = useStore();
  const { flow, user } = storeMethods;

  const methods = {
    isSpecialtySelected: () => {
      const specialty = flow.get('specialty');
      return specialty.value;
    },
    isSpecialistSelected: () => {
      const specialist = flow.get('specialist');
      return specialist.value;
    },
    isSlotSelected: () => {
      const slot = flow.get('selectedBlock');
      return slot.value;
    },
    isUserRegister: () => {
      const id = user.get('id');
      return !!id;
    }
  };

  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading</div>}>
        <Switch>
          <Route path="/" exact>
            <Ingress />
          </Route>
          <Route path="/booking" exact>
            <Booking />
          </Route>
          <Route path="/specialty" exact>
            {methods.isSpecialtySelected() ? <Specialty /> : <Redirect to="/" />}
          </Route>
          <Route path="/specialist" exact>
            {methods.isSpecialistSelected() ? <Specialist /> : <Redirect to="/" />}
          </Route>
          <Route path="/reservation" exact>
            {methods.isSlotSelected() ? <Reservation /> : <Redirect to="/" />}
          </Route>
          <Route path="/success" exact>
            {methods.isUserRegister() ? <Success /> : <Redirect to="/" />}
          </Route>
          <Route>
            <Page404 />
          </Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
const SkedRouter: React.FC = () => {
  const { stores: skedStores } = UseSkedStore();
  const { flowSked } = skedStores;

  // const { state: storePatient } = UseSkedPatient();
  const { validRut } = useSelector((state: RootStore) => state.patientsReducer);
  const flowStore = useSelector((state: RootStore) => state.flowReducer);

  const methods = {
    isRutValidated: () => {
      return validRut;
    },
    isSlotSelected: () => {
      return flowStore.selectedBlock.value !== '';
    },
    areAllFilled: (keys: (keyof FlowState)[]) => {
      return keys.every((key: keyof FlowState) => {
        return flowStore[key].value !== '';
      });
    },
    areSpecialtyFieldsFilled: () => {
      return methods.areAllFilled(['area', 'location', 'plan', 'service']);
    },
    areDoctorFieldsFilled: () => {
      return methods.areAllFilled(['doctor', 'area', 'location', 'plan', 'service']);
    }
  };

  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div>
            <Fade in={true}>
              <div
                style={{
                  height: true ? 'calc(100vh - 294.9px)' : 0,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Animation name="loading" width="85%" height="65%" loop autoplay />
              </div>
            </Fade>
          </div>
        }
      >
        <Switch>
          <Route path="/" exact>
            <VerifyRut />
          </Route>
          <Route path="/busqueda" exact>
            {methods.isRutValidated() ? <BookingSked /> : <Redirect to="/" />}
          </Route>
          <Route path="/especialidad" exact>
            {methods.areSpecialtyFieldsFilled() ? <SpecialtySked /> : <Redirect to="/" />}
          </Route>
          <Route path="/doctor" exact>
            {methods.areDoctorFieldsFilled() ? <Doctor /> : <Redirect to="/" />}
          </Route>
          <Route path="/registro" exact>
            {methods.isSlotSelected() ? <SkedRegister /> : <Redirect to="/" />}
          </Route>
          <Route path="/contactabilidad" exact>
            {methods.isSlotSelected() ? <SkedContactability /> : <Redirect to="/" />}
          </Route>
          <Route path="/resumen-reserva" exact>
            <SkedSuccess />
            {/* {methods.isSlotSelected() ? <SkedSuccess /> : <Redirect to="/" />} */}
          </Route>
          <Route>
            <Page404 />
          </Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

const Router = () => {
  if (isSkedClient()) {
    return SkedRouter;
  } else {
    return DefaultRouter;
  }
};

export default Router();
