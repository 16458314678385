import React, { createContext, useContext, Dispatch } from 'react';
import usePatientStoreSked from '../../context/sked/patient/patientStoreSked';
import { Action, FindPatientProps } from '../../context/sked/patient/patientDefinitionsSked';

import { State, HttpResponseStructure } from '../../context/sked/patient/patientDefinitionsSked';
import Patient from '../../../domain/models/sked/Patient';

interface InitContextProps {
  state: State;
  dispatch: Dispatch<Action>;
  findPatient: (props: FindPatientProps) => Promise<HttpResponseStructure>;
  patientIsRegistered: () => boolean;
  setPatient: (patient: Patient) => Promise<void>;
}

const authContext = createContext({} as InitContextProps);

/**
 * Provider
 * @param children
 * @constructor
 */
const PatientSkedProvider = ({ children }: any) => {
  const auth = usePatientStoreSked();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

/**
 * Use Auth for components
 */
const UseSkedPatient = (): InitContextProps => useContext(authContext);

export { PatientSkedProvider, UseSkedPatient };
