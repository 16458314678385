// Client
import { httpClient, token } from '../client';
import { format, clean } from 'rut.js';

// Definitions
import { AxiosResponse, CancelTokenSource } from 'axios';
import { Appointments } from '../../../../domain/models';

// Resource
const resource = '/appointments';

// Dynamic token source
let source: CancelTokenSource;

// Definitions
type CreateAppointmentProps = {
  service: string;
  resource: string;
  center: string;
  patient: string;
  coveragePlan: string;
  dateTimeFrom: string;
  status: string;
  duration: string;
  isOvercapacity: string;
  numberOfSlots: string;
  appointmentTypeId: string;
  options: {
    disableNotifications: string;
  };
};

type GetAppointmentProp = {
  id: string;
  dni: string; // FORMAT 12.345.678-9
};

export default {
  cancel: () => source?.cancel('Service cancelled'),
  async create(data: CreateAppointmentProps): Promise<AxiosResponse<string>> {
    const tokenApi = sessionStorage.getItem('token')
    return httpClient.post(`${resource}`, {
      ...data
    }, {
        headers: {
          Authorization: tokenApi
        }
    });
  },
  async get(props: GetAppointmentProp): Promise<AxiosResponse<Appointments>> {
    const { id, dni } = props;
    const tokenApi = sessionStorage.getItem('token')
    source = token.source();
    const formattedDni = format(clean(dni)).replace(/\./g, '');
    return httpClient.get(`${resource}/${id}?rut=${formattedDni}`, {
      cancelToken: source.token,
      headers: {
        Authorization: tokenApi
      }
    });
  }
};
