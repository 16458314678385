// Client
import { httpClient, token } from '../client';

// Definitions
import { AxiosResponse, CancelTokenSource } from 'axios';
import Doctor from '../../../../domain/models/sked/Doctor';

// Resource
const resource = '/search/resources';

const defaultParams = {
  searchPhrase: '',
  includeAvailabilities: true,
  onlyProfessionals: true,
  onlyAssignedToUser: false,
  onlyAssignedToLocationsOfUser: false,
  includeSelfPayer: false,
  count: true,
  exclusionList: [],
  forceRequest: false
};

export interface Props {
  locationId: string;
  coveragePlanId: string;
  areaId: string;
  searchPhrase?: string;
  includeAvailabilities?: boolean;
  onlyProfessionals?: boolean;
  onlyAssignedToUser?: boolean;
  onlyAssignedToLocationsOfUser?: boolean;
  includeSelfPayer?: boolean;
  count?: boolean;
  exclusionList?: string[];
  forceRequest?: boolean;
}

// Dynamic token source
let source: CancelTokenSource;

export default {
  async get(props: Props): Promise<AxiosResponse<Doctor[]>> {
    const tokenApi = sessionStorage.getItem('token')
    source = token.source();
    const params = Object.assign(defaultParams, props);
    return httpClient.post(`${resource}`, params, { cancelToken: source.token, headers: {
      Authorization: tokenApi
    } });
  }
};
